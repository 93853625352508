import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ReducerData,
  ReducerGraphic,
  Reducers,
  ReducerUser,
} from "../types/reducers";
import { WatchType } from "../utilities/enum/watchtype";
import {
  getFilteredMovie,
  getFilteredTvShow,
  getMovie,
  getTvShow,
} from "../store/actions/thunk_actions";
import { Filter } from "../types/data";
import { setFiltering, setSelectedWatch } from "../store/actions/graphic";
import moment from "moment";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { sendDataToAnalytics } from "../resources/analytics-requests";

const MobileFooter: React.FC = () => {
  const data: ReducerData = useSelector((store: Reducers) => store.data);
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const user: ReducerUser = useSelector((store: Reducers) => store.user);
  const dispatch = useDispatch();

  const startScanMovie = async () => {
    dispatch(setSelectedWatch(WatchType.movie));
    if (
      user.selectedWatchType !== null &&
      user.selectedWatchType == WatchType.movie
    ) {
      const filterObject: Filter = {
        genres: user.selectedGenres,
        startDate: user.startDate,
        endDate: user.endDate,
        providers: user.selectedProviders,
      };
      if (process.env!.NODE_ENV === "production") {
        sendDataToAnalytics(filterObject, "filter_movie");
      }
      dispatch(getFilteredMovie(filterObject));
    } else {
      dispatch(getMovie());
    }
  };

  const startScanTvShow = async () => {
    dispatch(setSelectedWatch(WatchType.tvshow));
    if (
      user.selectedWatchType !== null &&
      user.selectedWatchType == WatchType.tvshow
    ) {
      const filterObject: Filter = {
        genres: user.selectedGenres,
        startDate: user.startDate,
        endDate: user.endDate,
        providers: user.selectedProviders,
      };
      if (process.env!.NODE_ENV === "production") {
        sendDataToAnalytics(filterObject, "filter_tvshow");
      }
      dispatch(getFilteredTvShow(filterObject));
    } else {
      dispatch(getTvShow());
    }
  };

  const renderFilterRecap = () => {
    if (user.selectedWatchType == null) {
      return;
    }
    if (
      user.selectedWatchType == WatchType.movie &&
      graphic.selectedWatch == user.selectedWatchType
    ) {
      return (
        <div className="filterRecap">
          <h2>Hai filtrato la ricerca per:</h2>
          {user.selectedGenres.length > 0 && (
            <div className="filterRecapSection">
              <h3>Generi</h3>
              <div className="filterRecapList">
                {user.selectedGenres.map((g) => {
                  return (
                    <li className="genreMovie" key={g?.id.toString()}>
                      {g?.name}
                    </li>
                  );
                })}
              </div>
            </div>
          )}
          <div className="filterRecapSection">
            <h3>Anno di rilascio</h3>
            <p>
              Dal 01-01-{user.startDate.substr(0, 4)} al{" "}
              {Number(user.endDate.substr(0, 4)) !== moment().year()
                ? "31-12-" + user.endDate.substr(0, 4)
                : moment().format("DD-MM-YYYY")}
            </p>
          </div>
          {user.selectedProviders.length > 0 && (
            <div className="filterRecapSection">
              <h3>Piattaforme</h3>
              <div className="filterRecapList">
                {user.selectedProviders.map((p) => {
                  return (
                    <img
                      key={p.provider_id.toString()}
                      className="logoProvider"
                      src={p.logo_path}
                      alt={p.provider_name}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
    }
    if (
      user.selectedWatchType == WatchType.tvshow &&
      graphic.selectedWatch == user.selectedWatchType
    ) {
      return (
        <div style={{ borderColor: "#ff0013" }} className="filterRecap">
          <h2>Hai filtrato la ricerca per:</h2>
          {user.selectedGenres.length > 0 && (
            <div className="filterRecapSection">
              <h3>Generi</h3>
              <div className="filterRecapList">
                {user.selectedGenres.map((g) => {
                  return (
                    <li className="genreTvShow" key={g?.id.toString()}>
                      {g?.name}
                    </li>
                  );
                })}
              </div>
            </div>
          )}
          <div className="filterRecapSection">
            <h3>Anno di rilascio</h3>
            <p>
              Dal 01-01-{user.startDate.substr(0, 4)} al{" "}
              {Number(user.endDate.substr(0, 4)) !== moment().year()
                ? "31-12-" + user.endDate.substr(0, 4)
                : moment().format("DD-MM-YYYY")}
            </p>
          </div>
          {user.selectedProviders.length > 0 && (
            <div className="filterRecapSection">
              <h3>Piattaforme</h3>
              <div className="filterRecapList">
                {user.selectedProviders.map((p) => {
                  return (
                    <img
                      key={p.provider_id.toString()}
                      className="logoProvider"
                      src={p.logo_path}
                      alt={p.provider_name}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="buttonFooter">
      {renderFilterRecap()}
      <p
        className="buttonSearchAgainMobile movie"
        onClick={() => {
          startScanMovie();
        }}
      >
        Ricerca un film
      </p>
      <p
        className="buttonSearchAgainMobile tvShow"
        onClick={() => {
          startScanTvShow();
        }}
      >
        Ricerca una serie tv
      </p>
      <a
        className="buttonSearchAgainMobile buttonFilter"
        onClick={() => dispatch(setFiltering(true))}
      >
        Modifica filtro
      </a>
    </div>
  );
};

export default MobileFooter;
