import { getDataOnPage, getTvShowData } from "./../../utilities/functions";
import { Filter } from "./../../types/data";
import { setIsLoading, setSelectedWatch } from "./graphic";
import {
  API_KEY,
  FILTER_MOVIE,
  FILTER_SHOWS,
} from "./../../resources/constants";
import {
  setLatestIdMovie,
  setLatestIdShow,
  setMovie,
  setTvShow,
  setMovieGenres,
  setShowGenres,
  setMovieProviders,
  setShowProviders,
  setDataFound,
} from "./data";
import axios from "axios";
import { Reducers } from "../../types/reducers";
import { Movie, TvShow } from "../../types/data";
import moment from "moment";
import { getMovieData } from "../../utilities/functions";
import { WatchType } from "../../utilities/enum/watchtype";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import {
  getAllGenres,
  getAllProviders,
  getFiltered,
  getLatestMovie,
  getLatestShow,
  getRandom,
  Type,
} from "../../resources/movies-requests";
require("moment/min/locales");
moment.locale("it");

export const getLatestIdMovie: any =
  () => async (dispatch: (any: any) => void) => {
    const latestId = await getLatestMovie(API_KEY);
    dispatch(setLatestIdMovie(latestId));
  };

export const getLatestIdShow: any =
  () => async (dispatch: (any: any) => void) => {
    const latestId = await getLatestShow(API_KEY);
    dispatch(setLatestIdShow(latestId));
  };

export const getMovieGenres: any =
  () => async (dispatch: (any: any) => void) => {
    const genres = await getAllGenres(API_KEY, Type.MOVIE);
    dispatch(setMovieGenres(genres));
  };

export const getShowGenres: any =
  () => async (dispatch: (any: any) => void) => {
    const genres = await getAllGenres(API_KEY, Type.TV_SHOW);
    dispatch(setShowGenres(genres));
  };

export const getMovieProviders: any =
  () => async (dispatch: (any: any) => void) => {
    const providers = await getAllProviders(API_KEY, Type.MOVIE);
    dispatch(setMovieProviders(providers));
  };

export const getShowProviders: any =
  () => async (dispatch: (any: any) => void) => {
    const providers = await getAllProviders(API_KEY, Type.TV_SHOW);
    dispatch(setShowProviders(providers));
  };

export const getMovie: any =
  () => async (dispatch: (any: any) => void, getState: () => Reducers) => {
    dispatch(setIsLoading(true));
    dispatch(setDataFound(true));
    dispatch(setMovie(null));
    dispatch(setTvShow(null));
    const randomNumber = Math.round(
      Math.random() * getState().data.latestIdMovie
    );
    //console.log(randomNumber)
    try {
      const genres = getState().data.movieGenres;
      const movie = (await getRandom(
        API_KEY,
        randomNumber,
        Type.MOVIE
      )) as Movie;
      if (movie.title && movie.overview && movie.poster_path && !movie.adult) {
        const movieComplete = await getMovieData(movie, genres);
        firebase.analytics().logEvent("search_movie");
        dispatch(setMovie(movieComplete));
      } else {
        return dispatch(getMovie());
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        console.clear();
      }
      return dispatch(getMovie());
    }
    dispatch(setIsLoading(false));
  };

export const getTvShow: any =
  () => async (dispatch: (any: any) => void, getState: () => Reducers) => {
    dispatch(setIsLoading(true));
    dispatch(setDataFound(true));
    dispatch(setMovie(null));
    dispatch(setTvShow(null));
    const randomNumber = Math.round(
      Math.random() * getState().data.latestIdShow
    );
    //console.log(randomNumber)
    try {
      const genres = getState().data.showGenres;
      const tvShow = (await getRandom(
        API_KEY,
        randomNumber,
        Type.TV_SHOW
      )) as TvShow;
      if (tvShow.name && tvShow.overview && tvShow.poster_path) {
        const tvshow = await getTvShowData(tvShow, genres);
        firebase.analytics().logEvent("search_tvshow");
        dispatch(setTvShow(tvshow));
      } else {
        return dispatch(getTvShow());
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        console.clear();
      }
      return dispatch(getTvShow());
    }
    dispatch(setIsLoading(false));
  };

export const getFilteredMovie: any =
  (filter: Filter) =>
  async (dispatch: (any: any) => void, getState: () => Reducers) => {
    dispatch(setSelectedWatch(WatchType.movie));
    dispatch(setIsLoading(true));
    dispatch(setDataFound(true));
    dispatch(setMovie(null));
    dispatch(setTvShow(null));
    const genres = getState().data.movieGenres;
    const currentFilter = filter;
    try {
      const totalPages = (await getFiltered(
        API_KEY,
        filter,
        Type.MOVIE
      )) as number;
      if (totalPages > 0) {
        const movies = (await getDataOnPage(
          filter,
          totalPages,
          Type.MOVIE
        )) as Movie[];
        const randomMovieIndex = Math.round(Math.random() * movies.length - 1);
        const movie = await getMovieData(movies[randomMovieIndex], genres);
        dispatch(setMovie(movie));
      }
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        console.clear();
      }
      return dispatch(getFilteredMovie(currentFilter));
    }
    dispatch(setIsLoading(false));
  };

export const getFilteredTvShow: any =
  (filter: Filter) =>
  async (dispatch: (any: any) => void, getState: () => Reducers) => {
    dispatch(setSelectedWatch(WatchType.tvshow));
    dispatch(setIsLoading(true));
    dispatch(setDataFound(true));
    dispatch(setMovie(null));
    dispatch(setTvShow(null));
    const genres = getState().data.movieGenres;
    const currentFilter = filter;
    try {
      const totalPages = (await getFiltered(
        API_KEY,
        filter,
        Type.TV_SHOW
      )) as number;
      if (totalPages > 0) {
        const tvShows = (await getDataOnPage(
          filter,
          totalPages,
          Type.TV_SHOW
        )) as TvShow[];
        const randomTvShowIndex = Math.round(
          Math.random() * tvShows.length - 1
        );
        const tvShow = await getTvShowData(tvShows[randomTvShowIndex], genres);
        dispatch(setTvShow(tvShow));
      }
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        console.clear();
      }
      return dispatch(getFilteredTvShow(currentFilter));
    }
    dispatch(setIsLoading(false));
  };
