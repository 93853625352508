import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./store/reducers";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import app from "firebase/compat/app";
import "firebase/compat/analytics";

app.initializeApp({
  apiKey: "AIzaSyC6G6JQD4-DDV9UympDvkvsXyMG5jAw49s",
  authDomain: "millennial-watch.firebaseapp.com",
  projectId: "millennial-watch",
  storageBucket: "millennial-watch.appspot.com",
  messagingSenderId: "394429622825",
  appId: "1:394429622825:web:839733570ed22046bd4a38",
  measurementId: "G-96JM8TRMEC",
});
app.analytics();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
