import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ReducerData,
  ReducerGraphic,
  Reducers,
  ReducerUser,
} from "../types/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faIdBadge,
  faUsers,
  faCalendar,
  faClock,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Cast, Genre, Provider } from "../types/data";
import { baseUrlImage } from "../resources/constants";
import { isMobile } from "react-device-detect";
import { setSelectedWatch } from "../store/actions/graphic";
import { getMovie, getTvShow } from "../store/actions/thunk_actions";
import { WatchType } from "../utilities/enum/watchtype";
import MobileFooter from "../components/MobileFooter";

const ResultMoviePage: React.FC = () => {
  const data: ReducerData = useSelector((store: Reducers) => store.data);
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const user: ReducerUser = useSelector((store: Reducers) => store.user);
  const dispatch = useDispatch();

  const startScanMovie = async () => {
    dispatch(setSelectedWatch(WatchType.movie));
    dispatch(getMovie());
  };

  const startScanTvShow = async () => {
    dispatch(setSelectedWatch(WatchType.tvshow));
    dispatch(getTvShow());
  };

  const renderGenres = () => {
    return data.movie?.genres.map((genre: any) => {
      let isSelected = false;
      if (
        user.selectedWatchType == WatchType.movie &&
        user.selectedGenres.find((g) => g.id == genre.id)
      ) {
        isSelected = true;
      }
      return (
        <li
          style={{
            color: isSelected ? "#fff" : "#fe16b4",
            backgroundColor: isSelected ? "#fe16b4" : "transparent",
          }}
          className="genreMovie"
          key={genre?.id.toString()}
        >
          {genre?.name}
        </li>
      );
    });
  };

  const renderActors = () => {
    const arrayNames: string[] = [];
    data.movie?.actors.forEach((actor: Cast) => {
      arrayNames.push(actor.name);
    });
    return arrayNames.toString().replace(/,/g, ", ");
  };

  const renderPlatforms = () => {
    const arrayPlatforms: Provider[] = [];
    if (data.movie?.watch && data.movie?.watch.buy) {
      data.movie?.watch.buy.forEach((service: Provider) => {
        arrayPlatforms.push(service);
      });
    }
    if (data.movie?.watch && data.movie?.watch.rent) {
      data.movie?.watch.rent.forEach((service: Provider) => {
        if (arrayPlatforms.includes(service)) {
          arrayPlatforms.push(service);
        }
      });
    }
    if (data.movie?.watch && data.movie?.watch.flatrate) {
      data.movie?.watch.flatrate.forEach((service: Provider) => {
        arrayPlatforms.push(service);
      });
    }
    if (data.movie?.watch && data.movie?.watch.ads) {
      data.movie?.watch.ads.forEach((service: Provider) => {
        arrayPlatforms.push(service);
      });
    }
    return arrayPlatforms.map((platform: Provider) => {
      let isSelected = false;
      if (
        user.selectedWatchType == WatchType.movie &&
        user.selectedProviders.find(
          (p) => p.provider_id == platform.provider_id
        )
      ) {
        isSelected = true;
      }
      return (
        <img
          style={{
            borderColor: isSelected ? "#fe16b4" : "transparent",
          }}
          key={platform.provider_id.toString()}
          className="logoProvider"
          src={baseUrlImage + platform.logo_path}
          alt={platform.provider_name}
        />
      );
    });
  };

  return (
    <div className="resultContainer">
      <div className="posterColumn">
        <img
          src={
            data.movie && data.movie.poster_path ? data.movie.poster_path : ""
          }
          className="poster"
        />
      </div>
      <div className="detailColumn">
        {data.movie && data.movie.genres.length > 0 && (
          <div className="genreList">{renderGenres()}</div>
        )}
        <div className="titleContainer">
          <h2>{data.movie?.title}</h2>
          {data.movie?.trailer !== null && (
            <FontAwesomeIcon
              icon={faPlayCircle}
              className="iconYouTube"
              onClick={() => {
                //@ts-ignore
                window.open(data.movie?.trailer, "_blank");
              }}
            />
          )}
        </div>
        {data.movie && data.movie.director !== null && (
          <div className="directorContainer">
            <FontAwesomeIcon icon={faIdBadge} className="iconDirector" />
            <p>{data.movie.director.name}</p>
          </div>
        )}
        {data.movie &&
          data.movie.actors !== null &&
          data.movie.actors.length > 0 && (
            <div className="actorsContainer">
              <FontAwesomeIcon icon={faUsers} className="iconDirector" />
              <p>{renderActors()}</p>
            </div>
          )}
        <div className="dataContainer">
          {data.movie?.release_date !== null && (
            <div className="calendarContainer">
              <FontAwesomeIcon icon={faCalendar} className="iconCalendar" />
              <p>{data.movie?.release_date}</p>
            </div>
          )}
          {data.movie?.runtime && (
            <div className="calendarContainer">
              <FontAwesomeIcon icon={faClock} className="iconCalendar" />
              <p>{data.movie?.runtime} min.</p>
            </div>
          )}
          {data.movie?.vote_average !== null && (
            <div className="calendarContainer">
              <FontAwesomeIcon icon={faStar} className="iconCalendar" />
              <p>
                {data.movie?.vote_average.toString().replace(".", ",")} / 10
              </p>
            </div>
          )}
        </div>
        <p className="overview">{data.movie?.overview}</p>
        {data.movie?.watch !== null && (
          <div className="watchContainer">
            <h3>Guardalo ora</h3>
            {renderPlatforms()}
          </div>
        )}
        {isMobile && <MobileFooter />}
      </div>
    </div>
  );
};

export default ResultMoviePage;
