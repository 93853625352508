import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Filter, Genre, Provider } from "../types/data";
import { ReducerData, Reducers, ReducerUser } from "../types/reducers";
import { WatchType } from "../utilities/enum/watchtype";
import { Range, getTrackBackground } from "react-range";
import moment from "moment";
import {
  setEndDate,
  setSelectedGenres,
  setSelectedProviders,
  setSelectedWatch,
  setStartDate,
} from "../store/actions/user";
import {
  getFilteredMovie,
  getFilteredTvShow,
} from "../store/actions/thunk_actions";
import { setFiltering } from "../store/actions/graphic";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { Type } from "../resources/movies-requests";
import { sendDataToAnalytics } from "../resources/analytics-requests";

const FilterPage: React.FC = () => {
  const data: ReducerData = useSelector((store: Reducers) => store.data);
  const user: ReducerUser = useSelector((store: Reducers) => store.user);
  const [selectedWatchType, setSelectedWatchType] = useState<WatchType>(
    user.selectedWatchType ? user.selectedWatchType : WatchType.movie
  );
  const [selectedMovieGenres, setSelectedMovieGenres] = useState<Genre[]>(
    user.selectedWatchType == WatchType.movie ? user.selectedGenres : []
  );
  const [selectedShowGenres, setSelectedShowGenres] = useState<Genre[]>(
    user.selectedWatchType == WatchType.tvshow ? user.selectedGenres : []
  );
  const [selectedMovieProviders, setSelectedMovieProviders] = useState<
    Provider[]
  >(user.selectedWatchType == WatchType.movie ? user.selectedProviders : []);
  const [selectedShowProviders, setSelectedShowProviders] = useState<
    Provider[]
  >(user.selectedWatchType == WatchType.tvshow ? user.selectedProviders : []);
  const [yearRange, setYearRange] = useState<number[]>([
    user.startDate !== "" ? Number(user.startDate.substr(0, 4)) : 1950,
    user.endDate !== "" ? Number(user.endDate.substr(0, 4)) : moment().year(),
  ]);
  const values = yearRange;
  const dispatch = useDispatch();

  const startSearch = () => {
    const genres =
      selectedWatchType == WatchType.movie
        ? selectedMovieGenres
        : selectedShowGenres;
    const providers =
      selectedWatchType == WatchType.movie
        ? selectedMovieProviders
        : selectedShowProviders;
    const startDate = yearRange[0] + "-01-01";
    const endDate =
      yearRange[1] == moment().year()
        ? moment().format("YYYY-MM-DD")
        : yearRange[1] + "-12-31";
    dispatch(setSelectedWatch(selectedWatchType));
    dispatch(setSelectedGenres(genres));
    dispatch(setStartDate(startDate));
    dispatch(setEndDate(endDate));
    dispatch(setSelectedProviders(providers));
    const filterObject: Filter = {
      genres: genres,
      startDate: startDate,
      endDate: endDate,
      providers: providers,
    };
    if (process.env!.NODE_ENV === "production") {
      sendDataToAnalytics(
        filterObject,
        WatchType.movie ? "filter_movie" : "filter_tvshow"
      );
    }
    if (selectedWatchType == WatchType.movie) {
      dispatch(getFilteredMovie(filterObject));
    } else {
      dispatch(getFilteredTvShow(filterObject));
    }
    dispatch(setFiltering(false));
  };

  const renderWatchTypeSelector = () => {
    return (
      <div className="filterSection">
        <h2 className="startTitle">Cosa vuoi guardare?</h2>
        <div
          className="watchTypeSelectorContainer"
          style={{
            borderColor:
              selectedWatchType == WatchType.movie ? "#f316b4" : "#ff0013",
          }}
        >
          <p
            className="watchTypeSelector"
            style={{
              borderRightStyle: "solid",
              borderRightWidth: 3,
              borderRightColor:
                selectedWatchType == WatchType.movie ? "#f316b4" : "#ff0013",
              background:
                selectedWatchType == WatchType.movie
                  ? "#f316b4"
                  : "transparent",
            }}
            onClick={() => setSelectedWatchType(WatchType.movie)}
          >
            Film
          </p>
          <p
            className="watchTypeSelector"
            style={{
              borderLeftStyle: "solid",
              borderLeftWidth: 3,
              borderLeftColor:
                selectedWatchType == WatchType.movie ? "#f316b4" : "#ff0013",
              background:
                selectedWatchType == WatchType.tvshow
                  ? "#ff0013"
                  : "transparent",
              color: selectedWatchType == WatchType.tvshow ? "#000" : "#fff",
            }}
            onClick={() => setSelectedWatchType(WatchType.tvshow)}
          >
            Serie TV
          </p>
        </div>
      </div>
    );
  };

  const setGenre = (item: Genre) => {
    switch (selectedWatchType) {
      case WatchType.movie:
        const currentMovieInArray = selectedMovieGenres.find(
          (movie) => movie.id == item.id
        );
        if (!currentMovieInArray) {
          setSelectedMovieGenres(selectedMovieGenres.concat(item));
        } else {
          setSelectedMovieGenres(
            selectedMovieGenres.filter((m) => m.id !== item.id)
          );
        }
        return;
      case WatchType.tvshow:
        const currentShowInArray = selectedShowGenres.find(
          (show) => show.id == item.id
        );
        if (!currentShowInArray) {
          setSelectedShowGenres(selectedShowGenres.concat(item));
        } else {
          setSelectedShowGenres(
            selectedShowGenres.filter((s) => s.id !== item.id)
          );
        }
        return;
    }
  };

  const genreItem = (item: Genre) => {
    let isSelected;
    switch (selectedWatchType) {
      case WatchType.movie:
        isSelected = selectedMovieGenres.find((movie) => movie.id == item.id);
        return (
          <p
            className="genreItem"
            key={item.id.toString()}
            style={{
              borderColor: "#f316b4",
              background: isSelected ? "#f316b4" : "transparent",
              color: isSelected ? "#fff" : "#f316b4",
            }}
            onClick={() => setGenre(item)}
          >
            {item.name}
          </p>
        );
      case WatchType.tvshow:
        isSelected = selectedShowGenres.find((show) => show.id == item.id);
        return (
          <p
            className="genreItem"
            key={item.id.toString()}
            style={{
              borderColor: "#ff0013",
              background: isSelected ? "#ff0013" : "transparent",
              color: isSelected ? "#000" : "#ff0013",
            }}
            onClick={() => setGenre(item)}
          >
            {item.name}
          </p>
        );
    }
  };

  const setProvider = (item: Provider) => {
    switch (selectedWatchType) {
      case WatchType.movie:
        const currentMovieInArray = selectedMovieProviders.find(
          (movie) => movie.provider_id == item.provider_id
        );
        if (!currentMovieInArray) {
          setSelectedMovieProviders(selectedMovieProviders.concat(item));
        } else {
          setSelectedMovieProviders(
            selectedMovieProviders.filter(
              (m) => m.provider_id !== item.provider_id
            )
          );
        }
        return;
      case WatchType.tvshow:
        const currentShowInArray = selectedShowProviders.find(
          (show) => show.provider_id == item.provider_id
        );
        if (!currentShowInArray) {
          setSelectedShowProviders(selectedShowProviders.concat(item));
        } else {
          setSelectedShowProviders(
            selectedShowProviders.filter(
              (s) => s.provider_id !== item.provider_id
            )
          );
        }
        return;
    }
  };

  const providerItem = (item: Provider) => {
    let isSelected;
    switch (selectedWatchType) {
      case WatchType.movie:
        isSelected = selectedMovieProviders.find(
          (movie) => movie.provider_id == item.provider_id
        );
        return (
          <div
            className="providerItem"
            key={item.provider_id.toString()}
            style={{
              borderColor: isSelected ? "#f316b4" : "transparent",
            }}
            onClick={() => setProvider(item)}
          >
            <img src={item.logo_path} />
          </div>
        );
      case WatchType.tvshow:
        isSelected = selectedShowProviders.find(
          (show) => show.provider_id == item.provider_id
        );
        return (
          <img
            className="providerItem"
            key={item.provider_id.toString()}
            style={{
              borderColor: isSelected ? "#ff0013" : "transparent",
            }}
            onClick={() => setProvider(item)}
            src={item.logo_path}
          />
        );
    }
  };

  const renderGenreSelector = () => {
    switch (selectedWatchType) {
      case WatchType.movie:
        return (
          <div className="filterSection">
            <h2 className="startTitle">Che generi vuoi vedere?</h2>
            <div className="genreListContainer">
              {data.movieGenres.map((genre) => {
                return genreItem(genre);
              })}
            </div>
          </div>
        );
      case WatchType.tvshow:
        return (
          <div className="filterSection">
            <h2 className="startTitle">Che genere vuoi vedere?</h2>
            <div className="genreListContainer">
              {data.showGenres.map((genre) => {
                return genreItem(genre);
              })}
            </div>
          </div>
        );
    }
  };

  const renderYearSelector = () => {
    return (
      <div className="filterSection">
        <h2 className="startTitle">
          In che anno è{" "}
          {selectedWatchType == WatchType.movie
            ? "uscito il film"
            : "uscita la serie TV"}
          ?
        </h2>
        <p
          style={{
            color: "#fff",
            fontSize: "3vh",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Dal {yearRange[0]} al {yearRange[1]}
        </p>
        <Range
          values={yearRange}
          step={1}
          min={1950}
          max={moment().year()}
          onChange={(years) => {
            setYearRange(years);
          }}
          renderMark={({ props, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "6px",
                position: "absolute",
                bottom: 0,
                width: "2px",
                visibility: Number.isInteger(index / 10) ? "visible" : "hidden",
                backgroundColor: "rgba(255,255,255,0.3)",
              }}
            />
          )}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "30px",
                display: "flex",
                width: "80vw",
                maxWidth: 600,
                margin: "auto",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "8px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values,
                    colors: [
                      "rgba(250,250,250,0.8)",
                      selectedWatchType == WatchType.movie
                        ? "rgba(243, 22, 180,0.7)"
                        : "rgba(247, 214, 50, 0.7)",
                      "rgba(250,250,250,0.8)",
                    ],
                    min: 1950,
                    max: moment().year(),
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, value }) => (
            <div
              {...props}
              className="thumbYear"
              style={{
                ...props.style,
                height: "30px",
                width: "30px",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #222",
                backgroundColor:
                  selectedWatchType == WatchType.movie ? "#f316b4" : "#ff0013",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "-28px",
                  color: selectedWatchType == WatchType.movie ? "#fff" : "#000",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: "4px",
                  borderRadius: "4px",
                  backgroundColor:
                    selectedWatchType == WatchType.movie
                      ? "#f316b4"
                      : "#ff0013",
                }}
              >
                {value}
              </div>
            </div>
          )}
        />
      </div>
    );
  };

  const rederPlatformSelector = () => {
    switch (selectedWatchType) {
      case WatchType.movie:
        return (
          <div className="filterSection">
            <h2 className="startTitle">Su quale piattaforma?</h2>
            <div className="genreListContainer">
              {data.movieProvider.map((provider) => {
                return providerItem(provider);
              })}
            </div>
          </div>
        );
      case WatchType.tvshow:
        return (
          <div className="filterSection">
            <h2 className="startTitle">Su quale piattaforma?</h2>
            <div className="genreListContainer">
              {data.showProvider.map((provider) => {
                return providerItem(provider);
              })}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="filterActions">
      {renderWatchTypeSelector()}
      {rederPlatformSelector()}
      {renderGenreSelector()}
      {renderYearSelector()}
      <a
        className="buttonFilter"
        onClick={() => {
          startSearch();
        }}
        style={{ marginTop: 50, marginBottom: 50 }}
      >
        Avvia la ricerca
      </a>
    </div>
  );
};

export default FilterPage;
