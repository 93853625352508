import { Filter, Genre } from "./../types/data";
import axios from "axios";
import moment from "moment";
import { API_KEY, baseUrlImage, baseYouTube } from "../resources/constants";
import { Movie, TvShow } from "../types/data";
import {
  getCredits,
  getFiltered,
  getProviders,
  getTrailer,
  Type,
} from "../resources/movies-requests";

export const getTimeString: (time: number) => string = (time: number) => {
  time = Number(time);
  var h = Math.floor(time / 3600).toString();
  var m = Math.floor((time % 3600) / 60).toString();
  var s = Math.floor((time % 3600) % 60).toString();

  h = h.length == 1 ? "0" + h : h;
  m = m.length == 1 ? "0" + m : m;
  s = s.length == 1 ? "0" + s : s;
  return h + "h " + m + " min.";
};

export const getMovieData = async (film: Movie, genres: Genre[]) => {
  const cast = await getCredits(API_KEY, film.id, Type.MOVIE);
  const trailer = await getTrailer(API_KEY, film.id, Type.MOVIE);
  const platforms = await getProviders(API_KEY, film.id, Type.MOVIE);
  let genresArray: Genre[] = [];
  if (film.genre_ids) {
    film.genre_ids.forEach((g) => {
      const genreFind = genres.find((genre) => genre.id == g);
      if (genreFind) {
        genresArray.push(genreFind);
      }
    });
  } else {
    genresArray = [...film.genres];
  }
  const movie: Movie = {
    ...film,
    genres: genresArray,
    poster_path: film.poster_path ? `${baseUrlImage}${film.poster_path}` : "",
    backdrop_path: film.backdrop_path
      ? `${baseUrlImage}${film.backdrop_path}`
      : "",
    actors: cast.actors,
    director: cast.director,
    trailer: trailer ? `${baseYouTube}${trailer}` : null,
    watch: platforms,
  };
  return movie;
};

export const getTvShowData = async (show: TvShow, genres: Genre[]) => {
  const cast = await getCredits(API_KEY, show.id, Type.TV_SHOW);
  const trailer = await getTrailer(API_KEY, show.id, Type.TV_SHOW);
  const platforms = await getProviders(API_KEY, show.id, Type.TV_SHOW);
  let genresArray: Genre[] = [];
  if (show.genre_ids) {
    show.genre_ids.forEach((g) => {
      const genreFind = genres.find((genre) => genre.id == g);
      if (genreFind) {
        genresArray.push(genreFind);
      }
    });
  } else {
    genresArray = [...show.genres];
  }
  const tvshow: TvShow = {
    ...show,
    poster_path: show.poster_path ? `${baseUrlImage}${show.poster_path}` : "",
    backdrop_path: show.backdrop_path
      ? `${baseUrlImage}${show.backdrop_path}`
      : "",
    genres: genresArray,
    first_air_date: moment(show.first_air_date).format("DD MMMM YYYY"),
    actors: cast.actors,
    director: cast.director,
    trailer: trailer ? `${baseYouTube}${trailer}` : null,
    watch: platforms,
  };
  return tvshow;
};

export const getDataOnPage = async (
  filter: Filter,
  totalpages: number,
  type: Type
): Promise<Movie[] | TvShow[] | null> => {
  const randomPage = Math.round(Math.random() * totalpages);
  return new Promise(async (resolve) => {
    const array = (await getFiltered(API_KEY, filter, type, randomPage)) as
      | Movie[]
      | TvShow[];
    if (array) {
      resolve(array);
    } else {
      getDataOnPage(filter, totalpages, type);
    }
  });
};
