import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import data from './data'
import graphic from './graphic'
import user from './user'

const config = {
    key: 'root',
    storage: storage,
    blacklist: [ 'data', "graphic"],
    whitelist: ['user']
}

// Group reducers
const rootReducer = combineReducers({
    data,
    graphic,
    user
})

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(config, rootReducer)

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
const store = createStoreWithMiddleware(persistedReducer)
const persistor = persistStore(store)

export { persistor, store }