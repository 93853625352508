import { Provider } from './../../types/data';
import { Genre, Movie, TvShow } from '../../types/data';
import { ReduxAction } from './../../types/reducers';

export enum DATA_ACTIONS {
    SET_MOVIE = 'dataActions/setMovie',
    SET_TVSHOW = "dataActions/setTvShow",
    SET_LATEST_ID_MOVIE = "dataActions/setLatestIdMovie",
    SET_LATEST_ID_SHOW = "dataActions/setLatestIdShow",
    SET_MOVIE_GENRES = "dataActions/setMovieGenres",
    SET_SHOW_GENRES = "dataActions/setShowGenres",
    SET_MOVIE_PROVIDERS = "dataActions/setMovieProviders",
    SET_SHOW_PROVIDERS = "dataActions/setShowProviders",
    SET_DATA_FOUND = "dataActions/setDataFound"
}

export const setMovie: ReduxAction<Movie | null | undefined> = (movie: Movie | null | undefined) => {
    return {
        type: DATA_ACTIONS.SET_MOVIE,
        payload: movie
    }
}

export const setTvShow: ReduxAction<TvShow | null | undefined> = (tvshow: TvShow | null | undefined) => {
    return {
        type: DATA_ACTIONS.SET_TVSHOW,
        payload: tvshow
    }
}

export const setLatestIdMovie: ReduxAction<number> = (id: number) => {
    return {
        type: DATA_ACTIONS.SET_LATEST_ID_MOVIE,
        payload: id
    }
}

export const setLatestIdShow: ReduxAction<number> = (id: number) => {
    return {
        type: DATA_ACTIONS.SET_LATEST_ID_SHOW,
        payload: id
    }
}

export const setMovieGenres: ReduxAction<Genre[]> = (genres: Genre[]) => {
    return {
        type: DATA_ACTIONS.SET_MOVIE_GENRES,
        payload: genres
    }
}

export const setShowGenres: ReduxAction<Genre[]> = (genres: Genre[]) => {
    return {
        type: DATA_ACTIONS.SET_SHOW_GENRES,
        payload: genres
    }
}

export const setMovieProviders: ReduxAction<Provider[]> = (providers: Provider[]) => {
    return {
        type: DATA_ACTIONS.SET_MOVIE_PROVIDERS,
        payload: providers
    }
}

export const setShowProviders: ReduxAction<Provider[]> = (providers: Provider[]) => {
    return {
        type: DATA_ACTIONS.SET_SHOW_PROVIDERS,
        payload: providers
    }
}

export const setDataFound: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: DATA_ACTIONS.SET_DATA_FOUND,
        payload: status
    }
}
