import React from "react";
import { useDispatch, useSelector } from "react-redux";
//import LOGO from "../resources/images/logo.png";
import RELOAD_SHOW_ICON from "../resources/images/reloadShowIcon.png";
import RELOAD_MOVIE_ICON from "../resources/images/reloadMovieIcon.png";
import FILTER_ICON from "../resources/images/filterIcon.png";
import CLOSE_ICON from "../resources/images/closeIcon.png";
import FILTERED_MOVIE from "../resources/images/filteredMovie.png";
import FILTERED_SHOW from "../resources/images/filteredShow.png";
import {
  ReducerData,
  ReducerGraphic,
  Reducers,
  ReducerUser,
} from "../types/reducers";
import { setFiltering, setSelectedWatch } from "../store/actions/graphic";
import { WatchType } from "../utilities/enum/watchtype";
import {
  getFilteredMovie,
  getFilteredTvShow,
  getMovie,
  getTvShow,
} from "../store/actions/thunk_actions";
import { isMobile } from "react-device-detect";
import { Filter, Genre } from "../types/data";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { LOGO } from "../resources/constants";
import { sendDataToAnalytics } from "../resources/analytics-requests";

const Header: React.FC = () => {
  const data: ReducerData = useSelector((store: Reducers) => store.data);
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const user: ReducerUser = useSelector((store: Reducers) => store.user);
  const dispatch = useDispatch();

  const startScanMovie = async () => {
    dispatch(setSelectedWatch(WatchType.movie));
    if (
      user.selectedWatchType !== null &&
      user.selectedWatchType == WatchType.movie
    ) {
      const filterObject: Filter = {
        genres: user.selectedGenres,
        startDate: user.startDate,
        endDate: user.endDate,
        providers: user.selectedProviders,
      };
      if (process.env!.NODE_ENV === "production") {
        sendDataToAnalytics(filterObject, "filter_movie");
      }
      dispatch(getFilteredMovie(filterObject));
    } else {
      dispatch(getMovie());
    }
  };

  const startScanTvShow = async () => {
    dispatch(setSelectedWatch(WatchType.tvshow));
    if (
      user.selectedWatchType !== null &&
      user.selectedWatchType == WatchType.tvshow
    ) {
      const filterObject: Filter = {
        genres: user.selectedGenres,
        startDate: user.startDate,
        endDate: user.endDate,
        providers: user.selectedProviders,
      };
      if (process.env!.NODE_ENV === "production") {
        sendDataToAnalytics(filterObject, "filter_tvshow");
      }
      dispatch(getFilteredTvShow(filterObject));
    } else {
      dispatch(getTvShow());
    }
  };

  const genreItem = (item: Genre) => {
    return (
      <p
        className="genreItem"
        key={item.id.toString()}
        style={{
          borderColor: "#f316b4",
          color:
            graphic.selectedWatch == WatchType.movie ? "#f316b4" : "#ff0013",
        }}
      >
        {item.name}
      </p>
    );
  };

  const renderGenreList = () => {
    if (user.selectedGenres.length == 0) {
      return (
        <p
          style={{
            color:
              graphic.selectedWatch == WatchType.movie ? "#f316b4" : "#ff0013",
          }}
        >
          Nessun genere selezionato
        </p>
      );
    }
    let shortArray: Genre[] = [];
    user.selectedGenres.forEach((m, i) => {
      if (i < 3) {
        shortArray.push(m);
      }
    });
    return (
      <div className="genreList">
        <p>
          {shortArray
            .map((g) => {
              return g.name;
            })
            .toString()
            .replace(",", ", ")}
          {shortArray.length < user.selectedGenres.length &&
            "e altri " + (user.selectedGenres.length - 3) + " generi"}
        </p>
      </div>
    );
  };

  const renderButtons = () => {
    if (
      (data.movie !== null || data.tvshow !== null) &&
      !graphic.isLoading &&
      !graphic.filtering
    ) {
      return (
        <div className="reloadContainer">
          <div className="reloadButtonContainer">
            <img
              src={RELOAD_MOVIE_ICON}
              className="reloadButton"
              onClick={() => startScanMovie()}
            />
            <p
              onClick={() => {
                startScanMovie();
              }}
            >
              {isMobile ? "Film" : "Ricerca un altro film"}
            </p>
          </div>
          <div className="reloadButtonContainer">
            <img
              src={RELOAD_SHOW_ICON}
              className="reloadButton"
              onClick={() => startScanTvShow()}
            />
            <p onClick={() => startScanTvShow()}>
              {isMobile ? "Serie TV" : "Ricerca un'altra serie TV"}
            </p>
          </div>
        </div>
      );
    }
  };

  const renderFilter = () => {
    if ((data.movie !== null || data.tvshow !== null) && !graphic.isLoading) {
      if (graphic.filtering) {
        return (
          <div className="filterSectionHeader">
            <div className="filterButtonContainer">
              <img
                src={CLOSE_ICON}
                className="reloadButton"
                onClick={() => dispatch(setFiltering(false))}
              />
              <p onClick={() => dispatch(setFiltering(false))}>Chiudi</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="filterSectionHeader">
            <div className="filterButtonContainer">
              {user.selectedWatchType == WatchType.movie && (
                <img src={FILTERED_MOVIE} className="filteredIconCheck" />
              )}
              {user.selectedWatchType == WatchType.tvshow && (
                <img src={FILTERED_SHOW} className="filteredIconCheck" />
              )}
              <img
                src={FILTER_ICON}
                className="reloadButton"
                onClick={() => dispatch(setFiltering(true))}
              />
              <p onClick={() => dispatch(setFiltering(true))}>Filtra</p>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div className="header">
      <img src={LOGO} className="logo" />
      {renderButtons()}
      {renderFilter()}
    </div>
  );
};

export default Header;
