import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { ReducerData, ReducerGraphic, Reducers } from "./types/reducers";
import { useDispatch, useSelector } from "react-redux";
import StartPage from "./pages/StartPage";
import {
  getLatestIdMovie,
  getLatestIdShow,
  getMovieGenres,
  getMovieProviders,
  getShowGenres,
  getShowProviders,
} from "./store/actions/thunk_actions";
import BACKGROUND from "./resources/images/bgStandard.jpg";
import Header from "./components/Header";
import Loader from "./components/loader/Loader";
import ResultMoviePage from "./pages/ResultMoviePage";
import NothingFoundPage from "./pages/NothingFoundPage";
import ResultTvShowPage from "./pages/ResultTvShowPage";
import FilterPage from "./pages/FilterPage";
import axios from "axios";

function App() {
  const data: ReducerData = useSelector((store: Reducers) => store.data);
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLatestIdMovie());
    dispatch(getLatestIdShow());
    dispatch(getMovieGenres());
    dispatch(getShowGenres());
    dispatch(getMovieProviders());
    dispatch(getShowProviders());
  }, []);

  const renderContent = () => {
    if (graphic.filtering) {
      return <FilterPage />;
    }
    if (graphic.isLoading) {
      return <Loader />;
    }
    if (data.dataFound == false) {
      return <NothingFoundPage />;
    }
    if (data.tvshow == null && data.movie == null) {
      return <StartPage />;
    }
    if (data.movie !== null) {
      return <ResultMoviePage />;
    }
    if (data.tvshow !== null) {
      return <ResultTvShowPage />;
    }
  };

  return (
    <div className="startPage">
      <img
        src={
          data.movie !== null &&
          data.movie?.backdrop_path !== null &&
          !graphic.isLoading
            ? data.movie?.backdrop_path
            : BACKGROUND
        }
        className="blurredImage"
      />
      <div className="container">
        <Header />
        <div className="content">{renderContent()}</div>
      </div>
    </div>
  );
}

export default App;
