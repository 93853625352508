import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { Filter } from "../types/data";
import { Type } from "./movies-requests";

export const sendDataToAnalytics = (
  filter: Filter,
  type: "filter_movie" | "filter_tvshow"
) => {
  const filterToLog = {
    genres:
      filter.genres.length > 0
        ? filter.genres
            .map((g) => {
              return g.name;
            })
            .toString()
        : "null",
    dates: filter.startDate + "//" + filter.endDate,
    providers:
      filter.providers.length > 0
        ? filter.providers
            .map((p) => {
              return p.provider_name;
            })
            .toString()
        : "null",
  };
  firebase.analytics().logEvent(type, filterToLog);
};
