import { WatchType } from './../../utilities/enum/watchtype';
import { ReduxAction } from './../../types/reducers';

export enum GRAPHIC_ACTIONS {
    SET_SELECTED_WATCH = 'graphicActions/setSelectedWatch',
    SET_IS_LOADING = "graphicActions/setIsLoading",
    SET_FILTERING = "graphicActions/setFiltering"
}

export const setSelectedWatch: ReduxAction<WatchType> = (selected: WatchType) => {
    return {
        type: GRAPHIC_ACTIONS.SET_SELECTED_WATCH,
        payload: selected
    }
}

export const setIsLoading: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_IS_LOADING,
        payload: status
    }
}

export const setFiltering: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_FILTERING,
        payload: status
    }
}
