import axios from "axios";
import {
  Cast,
  Crew,
  Filter,
  Genre,
  Movie,
  Provider,
  TvShow,
  Watch,
} from "../types/data";
import { baseUrlImage } from "./constants";
import QueryString from "qs";

const baseUrlTMDB = "https://api.themoviedb.org/3/";

export enum Type {
  MOVIE = "movie",
  TV_SHOW = "tv",
}

export const getLatestMovie = (token: string): Promise<number> => {
  return new Promise(async (resolve) => {
    await axios
      .get(`${baseUrlTMDB}${Type.MOVIE}/latest`, {
        params: { api_key: token, language: "it-IT" },
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((response: any) => resolve(response.data.id));
  });
};

export const getLatestShow = (token: string): Promise<number> => {
  return new Promise(async (resolve) => {
    await axios
      .get(`${baseUrlTMDB}${Type.TV_SHOW}/latest`, {
        params: { api_key: token, language: "it-IT" },
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((response: any) => resolve(response.data.id));
  });
};

export const getRandom = (
  token: string,
  idMovie: number,
  type: Type
): Promise<Movie | TvShow> => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${baseUrlTMDB}${type}/${idMovie}`, {
        params: { api_key: token, language: "it-IT" },
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((response: any) => resolve(response.data))
      .catch((e) => {
        reject(e);
      });
  });
};

export const getFiltered = (
  token: string,
  filter: Filter,
  type: Type,
  page?: number
): Promise<Movie[] | TvShow[] | number | null> => {
  let params: any = {
    api_key: token,
    language: "it-IT",
    include_adult: false,
    "release_date.gte": filter.startDate,
    "release_date.lte": filter.endDate,
  };
  if (filter.genres && filter.genres.length > 0) {
    const genresId = filter.genres.map((g) => {
      return g.id;
    });
    params = {
      ...params,
      with_genres: genresId.toString().replaceAll(",", "|"),
    };
  }
  if (filter.providers && filter.providers.length > 0) {
    const providersId = filter.providers.map((p) => {
      return p.provider_id;
    });
    params = {
      ...params,
      with_watch_providers: providersId.toString().replaceAll(",", "|"),
      watch_region: "IT",
    };
  }
  if (page) {
    params = {
      ...params,
      page: page,
    };
  }
  console.log(params.with_watch_providers);
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${baseUrlTMDB}discover/${type}`, {
        params: params,
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((res) => {
        if (res.data.total_results == 0) {
          resolve(null);
          return;
        }
        if (!page) {
          resolve(res.data.total_pages);
          return;
        }
        if (type == Type.MOVIE) {
          const filtered = res.data.results.filter(
            (f: Movie) => f.title && f.overview && f.poster_path
          );
          if (filtered.length > 0) {
            resolve(filtered);
          }
        } else {
          const filtered = res.data.results.filter(
            (f: TvShow) => f.name && f.overview && f.poster_path
          );
          if (filtered.length > 0) {
            resolve(filtered);
          }
        }
        resolve(null);
      })
      .catch((e) => reject(e));
  });
};

interface Credits {
  director: Crew;
  actors: Cast[];
}

export const getCredits = (
  token: string,
  idMovie: number,
  type: Type
): Promise<Credits> => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${baseUrlTMDB}${type}/${idMovie}/credits`, {
        params: { api_key: token, language: "it-IT" },
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((response: any) => {
        const actors =
          response.data.cast.length > 0 ? response.data.cast.slice(0, 5) : null;
        const director =
          response.data.crew.length > 0
            ? response.data.crew.find((el: any) => el.job == "Director")
            : null;
        const object = {
          actors: actors !== undefined ? actors : null,
          director: director !== undefined ? director : null,
        };
        resolve(object);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getTrailer = (
  token: string,
  idMovie: number,
  type: Type
): Promise<string | null> => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${baseUrlTMDB}${type}/${idMovie}/videos`, {
        params: { api_key: token, language: "it-IT" },
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((response: any) => {
        if (response.data.results.length == 0) {
          resolve(null);
        }
        const videoLink = response.data.results.find(
          (el: any) => el.site == "YouTube"
        );
        resolve(videoLink.key);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getProviders = (
  token: string,
  idMovie: number,
  type: Type
): Promise<Watch | null> => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${baseUrlTMDB}${type}/${idMovie}/watch/providers`, {
        params: { api_key: token, language: "it-IT" },
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((response: any) => {
        if (response.data.results.IT) {
          resolve(response.data.results.IT);
        }
        resolve(null);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllGenres = (token: string, type: Type): Promise<Genre[]> => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${baseUrlTMDB}genre/${type}/list`, {
        params: { api_key: token, language: "it-IT" },
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((response: any) => {
        if (response.data.genres) {
          resolve(response.data.genres);
        }
        resolve([]);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllProviders = (
  token: string,
  type: Type
): Promise<Provider[]> => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${baseUrlTMDB}watch/providers/${type}`, {
        params: { api_key: token, watch_region: "IT" },
        paramsSerializer: (params) =>
          QueryString.stringify(params, { encode: false }),
      })
      .then((response: any) => {
        if (response.data.results) {
          const providers = response.data.results.map((p: Provider) => {
            return {
              provider_id: p.provider_id,
              provider_name: p.provider_name,
              logo_path: `${baseUrlImage}${p.logo_path}`,
            };
          });
          resolve(providers);
        }
        resolve([]);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
