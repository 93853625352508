import { USER_ACTIONS } from "../actions/user";
import { ReducerUser, ReduxActionData } from "./../../types/reducers";

const defaultValue: ReducerUser = {
  selectedWatchType: null,
  selectedGenres: [],
  startDate: "",
  endDate: "",
  selectedProviders: [],
};

const userState: (
  state: ReducerUser,
  actions: ReduxActionData<any>
) => ReducerUser = (state = defaultValue, action: ReduxActionData<any>) => {
  switch (action.type) {
    case USER_ACTIONS.SET_SELECTED_WATCH:
      return {
        ...state,
        selectedWatchType: action.payload,
      };
    case USER_ACTIONS.SET_SELECTED_GENRES:
      return {
        ...state,
        selectedGenres: action.payload,
      };
    case USER_ACTIONS.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case USER_ACTIONS.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    case USER_ACTIONS.SET_SELECTED_PROVIDERS:
      return {
        ...state,
        selectedProviders: action.payload,
      };
    case USER_ACTIONS.CLEAN_FILTER:
      return defaultValue
    default:
      return state;
  }
};

export default userState;
