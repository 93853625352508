import { Provider } from './../../types/data';
import { Genre } from "../../types/data"
import { ReduxAction } from "../../types/reducers"
import { WatchType } from "../../utilities/enum/watchtype"

export enum USER_ACTIONS {
    SET_SELECTED_WATCH = 'userActions/setSelectedWatch',
    SET_SELECTED_GENRES = "userActions/setSelectedGenres",
    SET_START_DATE = "userActions/setStartDate",
    SET_END_DATE = "userActions/setEndDate",
    SET_SELECTED_PROVIDERS = "userActions/setSelectedProviders",
    CLEAN_FILTER = "userActions/cleanFilter"
}

export const setSelectedWatch: ReduxAction<WatchType> = (selected: WatchType) => {
    return {
        type: USER_ACTIONS.SET_SELECTED_WATCH,
        payload: selected
    }
}

export const setSelectedGenres: ReduxAction<Genre[]> = (genres: Genre[]) => {
    return {
        type: USER_ACTIONS.SET_SELECTED_GENRES,
        payload: genres
    }
}

export const setStartDate: ReduxAction<string> = (date: string) => {
    return {
        type: USER_ACTIONS.SET_START_DATE,
        payload: date
    }
}

export const setEndDate: ReduxAction<string> = (date: string) => {
    return {
        type: USER_ACTIONS.SET_END_DATE,
        payload: date
    }
}

export const setSelectedProviders: ReduxAction<Provider[]> = (providers: Provider[]) => {
    return {
        type: USER_ACTIONS.SET_SELECTED_PROVIDERS,
        payload: providers
    }
}

export const cleanFilter = () => {
    return {
        type: USER_ACTIONS.CLEAN_FILTER
    }
}