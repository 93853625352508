import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Reducers, ReducerUser } from '../types/reducers'
import { WatchType } from '../utilities/enum/watchtype'
import { setFiltering } from '../store/actions/graphic'

const NothingFoundPage:React.FC = () => {
    const dispatch = useDispatch()
    const user: ReducerUser = useSelector((store: Reducers) => store.user)

    return(
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column"}}>
            <h2 style={{color: "#fff", fontSize: "3vh", textAlign: "center"}}>Non ho trovato {user.selectedWatchType == WatchType.movie ? "nessun film" : "nessuna serie TV"} con questi criteri di ricerca</h2>
            <a className="buttonFilter" onClick={() => { dispatch(setFiltering(true)) }} style={{ marginTop: 50, marginBottom: 50 }}>Cambia criteri di ricerca</a>
        </div>
    )
}

export default NothingFoundPage