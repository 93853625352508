import { Filter, Genre, Provider } from "../types/data";

export const LOGO =
  "https://22hbgtest.s3.eu-west-2.amazonaws.com/millennial-watch/millennial-watch-logo.png";

export const API_KEY = "e90857441bad79e704bdbaa87fbace6b";

export const baseUrlImage = "https://image.tmdb.org/t/p/original";

export const baseYouTube = "https://www.youtube.com/watch?v=";

export const firebaseConfig = {
  apiKey: "AIzaSyC6G6JQD4-DDV9UympDvkvsXyMG5jAw49s",
  authDomain: "millennial-watch.firebaseapp.com",
  projectId: "millennial-watch",
  storageBucket: "millennial-watch.appspot.com",
  messagingSenderId: "394429622825",
  appId: "1:394429622825:web:839733570ed22046bd4a38",
  measurementId: "G-96JM8TRMEC",
};

export const FILTER_MOVIE = (api: string, filter: Filter, page?: number) => {
  let url =
    "https://api.themoviedb.org/3/discover/movie?api_key=" +
    api +
    "&language=it-IT&include_adult=false";
  if (filter.genres) {
    const genres = filter.genres.toString().replaceAll(",", "|");
    url += "&with_genres=" + genres;
  }
  if (filter.startDate && filter.endDate) {
    url +=
      "&release_date.gte=" +
      filter.startDate +
      "&release_date.lte=" +
      filter.endDate;
  }
  if (filter.providers) {
    const providers = filter.providers.toString().replaceAll(",", "|");
    url += "&with_watch_providers=" + providers + "&watch_region=IT";
  }
  if (page) {
    url += "&page=" + page;
  }
  return url;
};

export const FILTER_SHOWS = (api: string, filter: Filter, page?: number) => {
  let url =
    "https://api.themoviedb.org/3/discover/tv?api_key=" +
    api +
    "&language=it-IT&include_adult=false";
  if (filter.genres) {
    const genres = filter.genres.toString().replaceAll(",", "|");
    url += "&with_genres=" + genres;
  }
  if (filter.startDate && filter.endDate) {
    url +=
      "&air_date.gte=" + filter.startDate + "&air_date.lte=" + filter.endDate;
  }
  if (filter.providers) {
    const providers = filter.providers.toString().replaceAll(",", "|");
    url += "&with_watch_providers=" + providers + "&watch_region=IT";
  }
  if (page) {
    url += "&page=" + page;
  }
  return url;
};
