import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerData, ReducerGraphic, Reducers } from "../types/reducers";
import BACKGROUND from "../resources/images/bgStandard.jpg";
import MOVIE_ICON from "../resources/images/movieIcon.png";
import SHOW_ICON from "../resources/images/showIcon.png";
import Header from "../components/Header";
import { getMovie, getTvShow } from "../store/actions/thunk_actions";
import {
  setFiltering,
  setIsLoading,
  setSelectedWatch,
} from "../store/actions/graphic";
import { WatchType } from "../utilities/enum/watchtype";
import { cleanFilter } from "../store/actions/user";
import axios from "axios";

const StartPage: React.FC = () => {
  const data: ReducerData = useSelector((store: Reducers) => store.data);
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const dispatch = useDispatch();

  const startScanMovie = async () => {
    dispatch(setSelectedWatch(WatchType.movie));
    dispatch(getMovie());
  };

  const startScanTvShow = async () => {
    dispatch(setSelectedWatch(WatchType.tvshow));
    dispatch(getTvShow());
  };

  return (
    <div className="startActions">
      <h1 className="startTitle">Cerca subito...</h1>
      <div className="startButtons">
        <div className="chooseContainer">
          <img
            src={MOVIE_ICON}
            className="chooseIcon"
            onClick={() => {
              dispatch(cleanFilter());
              const bodyAnononymSearchData = {
                genres: [],
                platforms: [],
                years: [],
                isRegistered: false,
                type: "movie",
              };
              startScanMovie();
            }}
          />
          <p>Film</p>
        </div>
        <div className="chooseContainer">
          <img
            src={SHOW_ICON}
            className="chooseIcon"
            onClick={() => {
              dispatch(cleanFilter());
              const bodyAnononymSearchData = {
                genres: [],
                platforms: [],
                years: [],
                isRegistered: false,
                type: "tvshow",
              };
              startScanTvShow();
            }}
          />
          <p>Serie TV</p>
        </div>
      </div>
      <h2 className="startTitle">... oppure ...</h2>
      <a className="buttonFilter" onClick={() => dispatch(setFiltering(true))}>
        Personalizza la tua ricerca
      </a>
    </div>
  );
};

export default StartPage;
