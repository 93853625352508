import React from 'react'
import { useSelector } from 'react-redux'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import { WatchType } from '../../utilities/enum/watchtype'
import './Loader.css'

const Loader:React.FC = () => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    return(
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column"}}>
            <h2 style={{color: "#fff", fontSize: "3vh", textAlign: "center"}}>Sto cercando {graphic.selectedWatch == WatchType.movie ? "il film" : "la serie TV"} migliore per te...</h2>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader